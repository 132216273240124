<template>
    <div class="header__btns">
        <button @click="handleFilterOrder({sortBy:nameFilter,sortDesc:false})" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="10" heigth="10" :class="[(selectFilter=='ASC')?'select__button':'deselect_button']">
            <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/>
            </svg>
        </button>
        <button @click="handleFilterOrder({sortBy:nameFilter,sortDesc:true})" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"  width="10" heigth="10" :class="[(selectFilter=='DESC')?'select__button':'deselect_button']">
            <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
            </svg>
        </button>
    </div>
</template>
<script>
export default {
    data(){
        return{
            selectFilter:null
        }
    },
    props:{
        nameFilter:{
            type:String
        }
    },
    methods:{
        handleFilterOrder(context){
            const {sortDesc}=context
            this.selectFilter=(sortDesc)?'DESC':'ASC'
            this.$emit('sortingChanged',context)
        }
    }
}

</script>

<style scoped lang="scss">

.header__btns{
display: flex;
flex-direction: column;
}

.header__btns button{
background: none;
border: none;
padding: 0;
height: auto;
}

.deselect_button{
    fill: white;
}

.select__button{
    fill:black;
}
</style>